import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Field, Form, FormikErrors, useFormikContext } from 'formik';
import isEqual from 'lodash/isEqual';
import { pick, toNumber } from 'lodash';
import { useSelector } from 'react-redux';
import { Button, Spacer, Text, TextField as UIKitTextField, theme, useToast } from '@mtsbank/ui-kit';
import { FieldsProps, WithoutAuthFormValues } from '@components/TspPayee/withoutAuthorization/type';
import { chargeApi } from '@api/ump/charge-invoice';
import { Content } from '@components/Content/Content';
import { MoneyInputField } from '@components/FormFields/MoneyInputField';
import { CardData } from '@root/components/TspPayee/CardData/CardData';
import { configPaymentWithoutAuth } from '@components/TspPayee/config/configPaymentWithoutAuth';
import { FeeAndSum } from '@components/FeeAndSum/FeeAndSum';
import { MESSAGE_FOR_COMMISSION } from '@components/TspPayee/utils/constants';
import { uuid } from '@root/utils/generateUuid';
import { ProviderBalanceAndMetersRequest, ProviderBalanceAndMetersResponse } from '@open-api/ump/charge-invoice';
import { Currency } from '@root/types/generated';
import { formatDate, parseDate } from '@root/utils/formatDate/formatDate';
import { getPcekZone } from '@root/components/CardToCard/utils/helpers';
import { authHelper } from '@root/utils/authHelper/AuthHelper';
import { isNumber } from '@root/utils/format';
import { selectBindings } from '@root/selectors/processing';
import { fieldsFocusBlurGtm, paymentButtonClickGtm } from '@root/utils/gtm/tsp/events';
import { SBER_TSP_ID, SberPayContext } from '@root/pages/perevod_v_sber';
import { FormatAliasPhone, formatPhoneNumber } from '@root/utils/formatPhoneNumber';
import { Param, Payee } from '@open-api/ump/catalog-manager';
import { CheckBoxField } from '@root/components/FormFields/CheckboxField';
import { TextField } from '@root/components/FormFields/TextField';
import { AmountRecommendationResponse } from '@open-api/ump/uprs-recommendation';
import { dpowerApi } from '@root/api/ump/dpower';
import { useGetPaymentTerms } from '@root/hooks/useGetPaymentTerms';
import { collectServiceParams } from '../utils/serviceUtils';
import { BalanceDetails } from '../BalanceDetails';
import { WrapperFooter, WrapperHint, WrapperSelector, Wrapper } from './styled';
import { BalanceInquiryForm } from '../topFields/BalanceInquiryForm';
import { MOBILE_PARAM_NAME, MTS_GROUP_PARAM } from '../constants';
import { PhoneTspField } from '../topFields/PhoneTspField';
import { getComissionOtherBankForMTS, isBinWithCommission, isMtsInternetTv, isMtsPhone } from '../utils/helpers';
import { GCMtsFields } from '../topFields/GCMtsFields';
import { MtsPhoneFields } from '../topFields/MtsPhoneFields';
const {
  amountPaymentField,
  offerAgreement,
  fiscalEmailField,
  requestGetTermDataHidden,
  cardDataField,
  fiscalReceiptField
} = configPaymentWithoutAuth;
const ignoreTermsErrorKeys = [fiscalEmailField.name];
export const PaymentFormFields: FC<FieldsProps> = ({
  fields = [],
  isLoadingPaymentDo,
  isCommissionOtherBankForMTS,
  serviceInfo,
  isAmountReadonly,
  setTermsData,
  setPayeeData,
  setIsCommissionMTSPay,
  termsData
}) => {
  const {
    values,
    submitForm,
    setFieldValue,
    setFieldTouched,
    validateForm,
    errors,
    dirty
  } = useFormikContext<WithoutAuthFormValues>();
  const {
    isAuthenticated
  } = authHelper;
  const {
    balanceInquiryEnabled,
    invoiceSearchParams,
    serviceId,
    params,
    features,
    bindingTypes
  } = serviceInfo;
  const {
    validityPeriod,
    CVC,
    cardNumber,
    amount,
    requestGetTermData,
    paymentSource,
    fiscalReceipt,
    fiscalEmail
  } = values;
  const [userSberPay, setUserSberPay] = useState<string | null>(null);
  const [recommendedAmount, setRecommendedAmount] = useState<AmountRecommendationResponse>(null);
  const [mtsInternetTvPayee, setMtsInternetTvPayee] = useState<Payee>(null);
  const [fieldsList, setFieldsList] = useState(fields);
  const [balanceData, setBalanceData] = useState<ProviderBalanceAndMetersResponse>(null);
  const [balanceReqInProgress, setBalanceReqInProgress] = useState(false);
  const bindings = useSelector(selectBindings);
  const {
    getPaymentTerms,
    isLoading: isGetPaymentTermsLoading
  } = useGetPaymentTerms();
  const {
    toast
  } = useToast();
  const sberPayContext = useContext(SberPayContext);
  useEffect(() => {
    const isServiceMTS = !!features?.isMtsGroup;
    const selectedBinding = bindings?.find(({
      bindingId
    }) => bindingId === values.paymentSource);
    if (values?.cardNumber || selectedBinding?.maskedPan) {
      const isCommissionOtherBankForMTS = isBinWithCommission(selectedBinding?.maskedPan || values?.cardNumber);
      setIsCommissionMTSPay(!isCommissionOtherBankForMTS && isServiceMTS);
    }
  }, [values, serviceId, bindings]);
  useEffect(() => {
    if (recommendedAmount) {
      setFieldValue(amountPaymentField.name, recommendedAmount.amount);
    }
  }, [recommendedAmount]);
  const handlePaymentButtonClick = () => {
    paymentButtonClickGtm(serviceId);
    submitForm();
  };
  const handleBalanceRequestClick = () => {
    const invoicesKeys = invoiceSearchParams.map(param => param.name);
    const accountNumParamName = invoicesKeys.find(key => key.toLowerCase().includes('accountnumber'));
    const invoiceParamsValues = pick(values, invoicesKeys);
    if (!errors[accountNumParamName]) {
      setBalanceReqInProgress(true);
      chargeApi.getProviderBalanceWithCountersUsingPOST('mtsmon_site', ({
        requestId: uuid(),
        searchParams: {
          ...invoiceParamsValues
        }
      } as ProviderBalanceAndMetersRequest), {
        headers: {
          'client-id': 'mts-money-web-mtsid'
        }
      }).then(({
        data
      }) => {
        if (data.errorCode) {
          return Promise.reject(data);
        }
        const balanceData = data;
        setBalanceData(balanceData);
      }).catch(({
        data
      }) => {
        if (data?.errorMessage) {
          toast('error', 'Ошибка поиска баланса', (data.errorMessage as string) || '', {
            withClose: true,
            withTimeout: true,
            timeout: 3000
          });
        }
      }).finally(() => setBalanceReqInProgress(false));
    }
  };
  const paymentDatafilled = useMemo(() => values.amount && (values[cardDataField.cardNumber.name] && values[cardDataField.CVCField.name] && values[cardDataField.validityPeriodField.name] || values.paymentSource), [values]);
  const isRequiredDataFilled = useMemo(() => {
    const requiredParams = params.filter(param => param.required && !param.hidden);
    return requiredParams.every(param => values[param.name]);
  }, [values]);
  const fetchTerms = (errors: FormikErrors<WithoutAuthFormValues>) => {
    if (dirty && serviceId !== 'phone' && !Object.keys(errors).filter(key => !ignoreTermsErrorKeys.includes(key)).length && paymentDatafilled && isRequiredDataFilled) {
      const zone = getPcekZone(sberPayContext?.isAuthMTS);
      let options = sberPayContext?.headers;
      if (zone === 'anonymous') {
        options = {
          headers: {
            'client-Id': 'mts-money-web-mtsid',
            FhpSessionId: window.gibSessionId,
            FhpRequestId: window.gibRequestId
          }
        };
      }
      const validityPeriodDate = parseDate(validityPeriod, '%m/%y');
      const expiry = formatDate(validityPeriodDate, 'YYYYMM');
      const cardData = {
        expiry,
        pan: cardNumber.replace(/\s/g, ''),
        cvc: CVC
      };
      let defaultParams: Param[];
      let id: string;
      if (mtsInternetTvPayee) {
        defaultParams = mtsInternetTvPayee.params;
        values[defaultParams[0].name] = values[MTS_GROUP_PARAM];
        id = mtsInternetTvPayee.serviceId;
      } else {
        defaultParams = params;
        id = serviceId;
      }
      const serviceParams = collectServiceParams(defaultParams || [], values, {
        onlyRequired: false
      });
      if (fiscalReceipt && fiscalEmail) {
        serviceParams.payerEmail = values?.fiscalEmail;
      }
      const currentRequestGetTermData = {
        serviceId: id,
        currency: Currency.RUB,
        amount: isNumber(values.amount) ? values.amount : toNumber((values.amount as string)?.replace(',', '.')),
        serviceParams: JSON.stringify(serviceParams),
        ...(!paymentSource && cardData),
        ...(paymentSource && {
          bindingId: paymentSource
        })
      };
      if (!isEqual(requestGetTermData, currentRequestGetTermData)) {
        setFieldValue(requestGetTermDataHidden.name, currentRequestGetTermData);
        getPaymentTerms({
          ...currentRequestGetTermData,
          zone,
          amount: Number(amount),
          options,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onSuccess: data => setTermsData(data?.bindings.find(binding => binding.userSelected)),
          onError: () => {
            toast('error', 'Ошибка', 'Не удалось рассчитать комиссию', {
              withClose: true,
              withTimeout: true,
              timeout: 3000
            });
            setTermsData(null);
          }
        });
      }
    }
  };
  const checkForm = async () => {
    const errors = await validateForm(values);
    fetchTerms(errors);
  };
  const handleFormBlur = () => {
    checkForm();
  };
  useEffect(() => {
    checkForm();
  }, [values?.paymentSource]);
  useEffect(() => {
    if (!isEqual(fields, fieldsList)) {
      setFieldsList(fields);
    }
  }, [fields, setFieldsList, fieldsList]);
  useEffect(() => {
    if (window.location.href.includes('phone') && serviceInfo.params[0].name) {
      setFieldValue(serviceInfo.params[0].name, values[MOBILE_PARAM_NAME]);
    }
  }, [serviceInfo, values[MOBILE_PARAM_NAME]]);
  const isBanalceCheckShow = useMemo(() => isAuthenticated() && balanceInquiryEnabled && !serviceInfo?.features?.isMtsGroup, [isAuthenticated, balanceInquiryEnabled, serviceInfo]);
  const feeCommission = isCommissionOtherBankForMTS ? getComissionOtherBankForMTS(toNumber(termsData?.amount?.base?.toString().replace(',', '.'))) : termsData?.amount?.fee;
  const handleAmountFocusBlur = () => {
    fieldsFocusBlurGtm(serviceId, amountPaymentField.label);
  };
  const fiscalReceiptOnChange = () => {
    setFieldTouched(fiscalReceiptField.name, true);
    setFieldValue(fiscalReceiptField.name, !values.fiscalReceipt);
  };
  const renderFirstField = field => {
    if (isBanalceCheckShow) {
      return <BalanceInquiryForm isLoading={balanceReqInProgress} onClick={handleBalanceRequestClick} field={field} />;
    }
    if (window.location.href.includes('phone')) {
      return <PhoneTspField setRecommendedAmount={setRecommendedAmount} setPayeeData={setPayeeData} />;
    }
    if (isMtsPhone(serviceId)) {
      return <MtsPhoneFields field={field} setRecommendedAmount={setRecommendedAmount} />;
    }
    if (isMtsInternetTv(serviceId)) {
      return <GCMtsFields setRecommendedAmount={setRecommendedAmount} setMtsInternetTvPayee={setMtsInternetTvPayee} field={field} serviceId={serviceId} />;
    }
    return <Field {...field} size="lg" />;
  };
  useEffect(() => {
    if (serviceId === SBER_TSP_ID) {
      const phoneNumber = (values?.id1 as string);
      if (formatPhoneNumber(phoneNumber)) {
        dpowerApi.sberCheckPhone(formatPhoneNumber(phoneNumber, FormatAliasPhone.DIGIT11)).then(res => {
          if (res?.data?.customerName) {
            setUserSberPay(res?.data?.customerName);
          } else {
            setUserSberPay(null);
            toast('error', 'Ошибка', 'Перевод по этому номеру телефона в СБЕР недоступен', {
              withClose: true,
              withTimeout: true,
              timeout: 3000
            });
          }
        }).catch(() => {
          setUserSberPay(null);
        });
      } else {
        setUserSberPay(null);
      }
    }
  }, [values?.id1, serviceId, toast]);
  return <Form onBlur={handleFormBlur}>
      <Wrapper>
        {fieldsList.map((field, index) => <Content marginBottom={theme.spacings.xs} key={`field-wrapper_${field.name}_${serviceInfo?.serviceId}`}>
            {index === 0 ? renderFirstField(field) : <Field {...field} size="lg" />}
          </Content>)}

        {serviceId === SBER_TSP_ID && userSberPay && <>
            <UIKitTextField disabled label="Получатель" value={userSberPay} />
            <Spacer space={16} spacemob={16} />
          </>}

        {!balanceReqInProgress && balanceData && <BalanceDetails balanceData={balanceData} />}
        <>
          <Content marginBottom={theme.spacings.xs}>
            <Field onFocus={handleAmountFocusBlur} onBlur={handleAmountFocusBlur} component={MoneyInputField} {...amountPaymentField} readOnly={isAmountReadonly} size="lg" hint={recommendedAmount?.hint} />
          </Content>
          <WrapperSelector>
            <CardData amount={(amount as number)} bindingTypes={bindingTypes} />
          </WrapperSelector>
          {!!features?.isMtsGroup && <>
              <Content marginBottom={theme.spacings.md}>
                <Field component={CheckBoxField} name={fiscalReceiptField.name} onChange={fiscalReceiptOnChange}>
                  <Text sizemob="md">{fiscalReceiptField.text}</Text>
                </Field>
              </Content>
              {fiscalReceipt && <Content marginBottom={theme.spacings.md}>
                  <Field component={TextField} {...fiscalEmailField} />
                </Content>}
            </>}
          <Content marginBottom={theme.spacings.md}>
            <FeeAndSum fee={feeCommission} base={termsData?.amount?.base} inProgress={isGetPaymentTermsLoading} isInitialProgress={!termsData?.amount} message={isCommissionOtherBankForMTS && MESSAGE_FOR_COMMISSION} />
          </Content>
          <WrapperFooter>
            <WrapperHint>
              <Text color={theme.colors.neutral.g300} size="md">
                {offerAgreement}
              </Text>
            </WrapperHint>
            <Button size="lg" isLoading={isLoadingPaymentDo || isGetPaymentTermsLoading} onClick={handlePaymentButtonClick} disabled={!userSberPay && serviceId === SBER_TSP_ID}
          // disabled={!isValid || !dirty || !serviceInfo?.providerName}
          >
              Оплатить
            </Button>
          </WrapperFooter>
        </>
      </Wrapper>
    </Form>;
};